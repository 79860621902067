/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useContext, useEffect, useState } from 'react';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Dialog from '../components/atoms/Dialog/Dialog';
import FormInputField from '../components/atoms/FormInputField/FormInputField';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import AuthContext from '../context/AuthProvider';
import { getStorage } from '../helpers/general';

import { Flex, useToast } from '@chakra-ui/react';
import { Link } from 'gatsby';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import Icon from '../components/atoms/Icon/Icon';
import * as styles from './signup.module.scss';
import useSegment from 'hooks/useSegment';

const SignupPage = () => {
  const toast = useToast();
  const { identifyManual } = useSegment();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const handleRegistration = auth && auth.signup;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [fields, setFields] = useState({
    email: '',
    first_name: '',
    last_name: '',
    authentication: { new_password: '' },
    confirm_password: ''
  });

  const [emptyError, setEmptyError] = useState({
    email: false,
    first_name: false,
    last_name: false,
    password: false,
    confirm_password: false
  });

  const [dialogMessage, setDialogMessage] = useState(false);

  useEffect(() => {
    if (isLoggedIn && typeof window !== 'undefined') {
      const getLastPage = getStorage('lastPage');

      if (getLastPage) {
        window.location = getLastPage;
      } else {
        window.location = '/account';
      }
    }
  }, [isLoggedIn]);

  const attemptRegistration = e => {
    e.preventDefault();
    const valid = validateRequiredFields();

    if (valid) {
      if (
        validatePassword(
          fields['authentication'].new_password,
          fields['confirm_password']
        )
      ) {
        setLoading(true);
        handleRegistration(fields)
          .then(response => {
            identifyManual({
              email: fields.email,
              name: fields.first_name + ' ' + fields.last_name,
              id: response?.response?.object?.customerId
            });
          })
          .catch(error => {
            toast({
              title: 'Registration Error',
              description:
                error?.response?.message ||
                error?.message ||
                'Something went wrong. Please try again.',
              status: 'error',
              position: 'top-right',
              colorScheme: 'secondary'
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast({
          title: 'Validation Error',
          description: 'Passwords do not match',
          status: 'error',
          position: 'top-right',
          colorScheme: 'secondary'
        });
      }
    } else {
      toast({
        title: 'Validation Error',
        description: 'Please fill all available fields in.',
        status: 'error',
        position: 'top-right',
        colorScheme: 'secondary'
      });
    }
  };

  const validateRequiredFields = () => {
    const newError = emptyError;
    let isValid = true;

    Object.keys(emptyError).forEach(item => {
      if (item === 'password') {
        if (fields['authentication'].new_password !== '') {
          newError['password'] = false;
        } else {
          newError['password'] = true;
          isValid = false;
        }
      } else if (fields[item] !== '') {
        newError[item] = false;
      } else {
        newError[item] = true;
        isValid = false;
      }
    });

    setEmptyError(newError);

    return isValid;
  };

  const validatePassword = (pass, passConfirm) => {
    if (pass === passConfirm) {
      return true;
    } else {
      return false;
    }
  };

  const clearDialog = () => {
    setDialogMessage(false);
  };

  const handleChange = (id, fieldValue) => {
    const newField = {};

    if (id === 'password') {
      newField['authentication'] = { new_password: fieldValue };
    } else {
      newField[id] = fieldValue;
    }

    setFields({ ...fields, ...newField });
  };

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setDialogMessage(false);
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout className={styles.root}>
        <Seo title="Pet Chemist Online - Create Account" showSiteName={false} />
        <Container>
          <Breadcrumbs
            crumbs={[{ link: '/', label: '' }, { label: 'Create Account' }]}
          />

          <form
            className={styles.formSignUp}
            onSubmit={e => attemptRegistration(e)}
          >
            <div className="signup-container">
              <h1 className={styles.createAccountTitle}>Create an account</h1>
              <div className={styles.createAccountSubTitle}>Create an account to enjoy faster checkout, exclusive emailed discounts, and access to our AutoShip subscription program.</div>
              <div className={`grid ${styles.gridSignup}`}>
                <FormInputField
                  id="email"
                  type="email"
                  labelName="Email"
                  value={fields['email'] || userEmail}
                  handleChange={handleChange}
                  error={emptyError.email}
                />
                <div className={`grid grid-50 ${styles.gridPass}`}>
                  <FormInputField
                    id="password"
                    type="password"
                    labelName="Password"
                    value={fields['authentication'].new_password}
                    handleChange={handleChange}
                    error={emptyError.password}
                  />
                  <FormInputField
                    id="confirm_password"
                    type="password"
                    labelName="Confirm Password"
                    value={fields['confirm_password']}
                    handleChange={handleChange}
                    error={emptyError.confirm_password}
                  />
                </div>
                <div className={`grid grid-50 ${styles.gridPass}`}>
                  <FormInputField
                    id="first_name"
                    labelName="First Name"
                    value={fields['first_name']}
                    handleChange={handleChange}
                    error={emptyError.first_name}
                  />
                  <FormInputField
                    id="last_name"
                    labelName="Last Name"
                    value={fields['last_name']}
                    handleChange={handleChange}
                    error={emptyError.last_name}
                  />
                </div>
              </div>
              <div className="formField">
                <Button
                  level="tertiary"
                  loading={loading}
                  disabled={loading}
                  type="buttonSubmit"
                >
                  Create account
                </Button>
              </div>

              <Dialog
                open={dialogMessage ? true : false}
                title="Account creation unsuccessful"
                size="sm"
                hideBtnCancel
                disableBackdropClick
                onOk={() => clearDialog()}
                onClose={(event, reason) => handleCancel(event, reason)}
              >
                {dialogMessage}
              </Dialog>
            </div>

            <Flex className="previous-link" alignItems={'center'} gap={2}>
              <Icon symbol={'chevRight'} />
              <Link to="/login">Back to login</Link>
            </Flex>
          </form>
        </Container>
      </Layout>
    );
  } else {
    return null;
  }
};

export default SignupPage;
